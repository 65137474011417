<template>

  <div>
    <label :for="data.id" v-html="data.label" :class="{flixFocus: focus}"/>
    <span class="labelRequired" v-if="data.required">&nbsp;
      <span v-if="!focus">*</span>
      <span class="text" v-else>{{ $t('message.isRequired') }}</span>
    </span>
  </div>

</template>

<script>

export default {

  props: { data: Object, focus: Boolean }

}

</script>

<style lang="sass" scoped>
  @import '@/components/formBuilder/style/variables.sass'
  @import '@/components/formBuilder/style/form/label.sass'
</style>
